import React from "react"
import { BgImage } from "gbimage-bridge"
import PropTypes from "prop-types"

const NewsItem = ({ heading, date, time, content, link, image, linkText }) => (
  <BgImage image={image} className="news-item">
    <div className="news-item__content">
      <h2 className="news-item__heading">{heading}</h2>
      {(date || time) && (
        <p className="text text--small">
          {date} {time}
        </p>
      )}
      <p>
        {content}{" "}
        {link && (
          <>
            {!linkText && <>Läs mer på </>}
            <a href={link} className="news-item__link">
              {linkText ? linkText : link}
            </a>
            {!linkText && <>.</>}
          </>
        )}
      </p>
    </div>
  </BgImage>
)

// Note that values fetched from Google Sheets will be empty strings
// if they are not assigned to anything, so `isRequired` below doesn't
// do much really. We could add a custom validator instead.
NewsItem.propTypes = {
  heading: PropTypes.string.isRequired,
  date: PropTypes.string,
  time: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  image: PropTypes.object.isRequired,
}

export default NewsItem
