import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NewsItem from "../components/newsItem"
import { getNodesFromQuery } from "../functions"
import Calendar from "../components/calendar"

const IndexPage = ({ data }) => {
  const news = data.allGoogleSheetPuffarRow.edges
    .map(edge => ({
      showFrom: edge.node.visafran,
      showUntil: edge.node.visatillochmed,
      heading: edge.node.rubrik,
      date: edge.node.datum,
      time: edge.node.tid,
      content: edge.node.info,
      link: edge.node.lank,
      linkText: edge.node.lanktext,
    }))
    .map(newsItem => {
      const showFrom = newsItem.showFrom
      const showUntil = newsItem.showUntil
      const showFromDate = showFrom !== "" ? new Date(showFrom) : null
      const showUntilDate = showUntil !== "" ? new Date(showUntil) : null

      return { ...newsItem, showFrom: showFromDate, showUntil: showUntilDate }
    })
    .filter(newsItem => {
      const showFrom = newsItem.showFrom
      const showUntil = newsItem.showUntil
      const currentDate = new Date()

      if (showFrom === null && showUntil === null) {
        return true
      }

      if (showFrom !== null) {
        if (isNaN(showFrom.getDate())) {
          console.error(
            `Can't parse "showFrom" for news item with heading "${newsItem.heading}". Skipping item.`
          )
          return false
        }
      }

      if (showUntil !== null) {
        if (isNaN(showUntil.getDate())) {
          console.error(
            `Can't parse "showUntil" for news item with heading "${newsItem.heading}". Skipping item.`
          )
          return false
        }
      }

      if (showFrom !== null && showUntil === null) {
        return showFrom <= currentDate
      }

      if (showFrom === null && showUntil !== null) {
        return currentDate <= showUntil
      }

      if (showFrom > showUntil) {
        console.error(
          `showFrom is bigger than showUntil for item ${newsItem.heading}. Skipping item.`
        )
        return false
      }

      return showFrom <= currentDate && currentDate <= showUntil
    })

  const activities = getNodesFromQuery(data.activities.edges)
  const newsImages = getNodesFromQuery(data.newsImages.edges)

  return (
    <Layout>
      <Seo title="Hem" />
      <section className="article__content overlay-relative">
        <GatsbyImage
          image={data.church.childImageSharp.gatsbyImageData}
          alt="Björkbackskyrkan, Equmeniakyrkan Örbyhus"
        />
        <div className="text-overlay">
          <p className="text-overlay__content">En kyrka för hela livet</p>
        </div>
      </section>
      <section className="article__content article__content--full-width-on-small-screens">
        {news.map((newsItem, index) => {
          const image = newsImages[index].childImageSharp.gatsbyImageData

          return (
            <NewsItem
              heading={newsItem.heading}
              date={newsItem.date}
              time={newsItem.time}
              content={newsItem.content}
              link={newsItem.link}
              linkText={newsItem.linkText}
              key={index}
              image={image}
            />
          )
        })}
      </section>
      <section className="article__content article__content--columns">
        <div>
          {activities.map((activity, index) => (
            <Link
              to={activity.link}
              key={`activity-${index}`}
              className="activity"
            >
              <GatsbyImage
                image={activity.image.childImageSharp.gatsbyImageData}
                alt={activity.alt}
              />
            </Link>
          ))}
        </div>
        <div className="calendar">
          <h2>Program</h2>
          <Calendar
            calendarId="t67cgu3a94cjdgq2bk0aqvgie0@group.calendar.google.com"
            dateFormat='{"weekday":"short","month":"numeric","day":"numeric"}'
            daysForward={20}
            showCalendarLink={true}
          />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allGoogleSheetPuffarRow {
      edges {
        node {
          datum
          info
          lank
          lanktext
          rubrik
          tid
          visafran
          visatillochmed
        }
      }
    }
    newsImages: allFile(filter: { relativeDirectory: { eq: "news" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 1050, quality: 80)
          }
        }
      }
    }
    activities: allActivitiesYaml {
      edges {
        node {
          alt
          link
          image {
            childImageSharp {
              gatsbyImageData(width: 1050, quality: 80)
            }
          }
        }
      }
    }
    church: file(relativePath: { eq: "front.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1152, quality: 80)
      }
    }
  }
`
export default IndexPage
